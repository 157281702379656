// components
import SvgIconStyle from "../../../components/SvgIconStyle";

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  user: getIcon("ic_user"),
  ecommerce: getIcon("ic_ecommerce"),
  analytics: getIcon("ic_analytics"),
  dashboard: getIcon("ic_dashboard"),
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: "Main",
    items: [{ title: "Dashboard", path: "/dashboard/", icon: ICONS.dashboard }],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: "Class Management",
    items: [
      {
        title: "Class Sessions",
        path: "/dashboard/class-sessions",
        icon: ICONS.user,
        children: [
          { title: "List Classes", path: "/dashboard/class-sessions/list" },
          { title: "Add Class", path: "/dashboard/class-sessions/new" },
        ],
      },
      {
        title: "Announcements",
        path: "/dashboard/announcements",
        icon: ICONS.user,
        children: [
          { title: "Add Announcement", path: "/dashboard/announcements/new" },
        ],
      },
      {
        title: "Documents",
        path: "/dashboard/documents",
        icon: ICONS.user,
        children: [
          { title: "List Documents", path: "/dashboard/user/four" },
          { title: "Add Document", path: "/dashboard/user/five" },
        ],
      },
    ],
  },

  // STUDENT MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: "Student Management",
    items: [
      {
        title: "Students",
        path: "/dashboard/students",
        icon: ICONS.user,
        children: [
          { title: "List Students", path: "/dashboard/user/four" },
          { title: "Add Student", path: "/dashboard/user/five" },
        ],
      },
    ],
  },

  // BILLING MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: "Billing Management",
    items: [
      {
        title: "Outstanding Payments",
        path: "/dashboard/payments",
        icon: ICONS.user,
        children: [
          { title: "List", path: "/dashboard/user/four" },
          { title: "Add", path: "/dashboard/user/five" },
        ],
      },
    ],
  },
];

export default sidebarConfig;
