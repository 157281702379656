// routes
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// components
import Settings from "./components/settings";
import ScrollToTop from "./components/ScrollToTop";
import RtlLayout from "components/RtlLayout";
import { ProgressBarStyle } from "./components/ProgressBar";
import ThemeColorPresets from "./components/ThemeColorPresets";
import MotionLazyContainer from "./components/animate/MotionLazyContainer";
import NotistackProvider from "./utils/NotistackProvider";

// ----------------------------------------------------------------------

export default function App() {
  return (
    <ThemeProvider>
      <ThemeColorPresets>
        <RtlLayout>
          <MotionLazyContainer>
            <NotistackProvider>
              <ProgressBarStyle />

              <Settings />
              <ScrollToTop />
              <Router />
            </NotistackProvider>
          </MotionLazyContainer>
        </RtlLayout>
      </ThemeColorPresets>
    </ThemeProvider>
  );
}
