import { Suspense, lazy } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
// layouts
import DashboardLayout from "../layouts/dashboard";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
// components
import LoadingScreen from "../components/LoadingScreen";
import GuestGuard from "../guards/GuestGuard";
import AuthGuard from "../guards/AuthGuard";
// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense
      fallback={<LoadingScreen isDashboard={pathname.includes("/dashboard")} />}
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: "auth",
      children: [
        {
          path: "login",
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: "register",
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: "login-unprotected", element: <Login /> },
        { path: "register-unprotected", element: <Register /> },
        { path: "reset-password", element: <ResetPassword /> },
        { path: "verify", element: <VerifyCode /> },
      ],
    },

    {
      path: "/",
      element: <Navigate to="/dashboard/one" replace />,
    },
    {
      path: "/dashboard",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/dashboard/one" replace />, index: true },
        { path: "one", element: <PageOne /> },
        { path: "two", element: <PageTwo /> },
        { path: "three", element: <PageThree /> },
        {
          path: "class-sessions",
          children: [
            {
              element: <Navigate to="/dashboard/class-sessions/list" replace />,
              index: true,
            },
            {
              path: "list",
              element: <ClassSessionList />,
            },
            {
              path: "new",
              element: <NewClassSession />,
            },
            {
              path: ":classSessionId/details",
              element: <ClassSessionDetails />,
            },
            {
              path: ":classSessionId/edit",
              element: <UpdateClassSession />,
            },
            {
              path: ":classSessionId/roster",
              element: <ClassSessionRoster />,
            },
          ],
        },
        {
          path: "announcements",
          children: [
            {
              element: <Navigate to="/dashboard/announcements/new" replace />,
              index: true,
            },
            {
              path: "new",
              element: <NewAnnouncement />,
            },
            {
              path: ":announcementId/view",
              element: <ViewAnnouncement />,
            },
          ],
        },
        {
          path: "students",
          children: [
            {
              element: <Navigate to="/dashboard/students/list" replace />,
              index: true,
            },
            {
              path: "list",
              element: <PageOne />,
            },
            {
              path: ":studentId/details",
              element: <StudentDetails />,
            },
          ],
        },
        {
          path: "user",
          children: [
            {
              element: <Navigate to="/dashboard/user/four" replace />,
              index: true,
            },
            { path: "four", element: <PageFour /> },
            { path: "five", element: <PageFive /> },
            { path: "six", element: <PageSix /> },
          ],
        },
      ],
    },
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

// Dashboard
const PageOne = Loadable(lazy(() => import("../pages/PageOne")));
const PageTwo = Loadable(lazy(() => import("../pages/PageTwo")));
const PageThree = Loadable(lazy(() => import("../pages/PageThree")));
const PageFour = Loadable(lazy(() => import("../pages/PageFour")));
const PageFive = Loadable(lazy(() => import("../pages/PageFive")));
const PageSix = Loadable(lazy(() => import("../pages/PageSix")));
const NotFound = Loadable(lazy(() => import("../pages/Page404")));
const NewAnnouncement = Loadable(
  lazy(() => import("../pages/dashboard/ClassManagement/NewAnnouncement"))
);
const ViewAnnouncement = Loadable(
  lazy(() => import("../pages/dashboard/ClassManagement/ViewAnnouncement"))
);
const NewClassSession = Loadable(
  lazy(() => import("../pages/dashboard/ClassManagement/NewClassSession"))
);
const UpdateClassSession = Loadable(
  lazy(() => import("../pages/dashboard/ClassManagement/UpdateClassSession"))
);
const ClassSessionList = Loadable(
  lazy(() => import("../pages/dashboard/ClassManagement/ClassSessionList"))
);
const ClassSessionDetails = Loadable(
  lazy(() => import("../pages/dashboard/ClassManagement/ClassSessionDetails"))
);
const ClassSessionRoster = Loadable(
  lazy(() => import("../pages/dashboard/ClassManagement/ClassSessionRoster"))
);
const StudentDetails = Loadable(
  lazy(() => import("../pages/dashboard/StudentManagement/StudentDetails"))
);

// AUTHENTICATION
const Login = Loadable(lazy(() => import("../pages/auth/Login")));
const Register = Loadable(lazy(() => import("../pages/auth/Register")));
const ResetPassword = Loadable(
  lazy(() => import("../pages/auth/ResetPassword"))
);
const VerifyCode = Loadable(lazy(() => import("../pages/auth/VerifyCode")));
