import PropTypes from "prop-types";
// form
import { useFormContext, Controller } from "react-hook-form";
// @mui
import { FormControl, FormHelperText, InputLabel, Select } from "@mui/material";

// ----------------------------------------------------------------------

RHFSelect.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string,
};

export default function RHFSelect({ name, children, label, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl fullWidth sx={{ marginTop: 2 }}>
          <InputLabel>{label}</InputLabel>
          <Select
            {...field}
            multiple
            label={label}
            fullWidth
            SelectProps={{ native: true }}
            error={!!error}
            helperText={error?.message}
            {...other}
          >
            {children}
          </Select>
          <FormHelperText error={error}>{error?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
}
